@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
@import url('https://rsms.me/inter/inter.css');


/* CUSTOMIZER */
.customizer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.customizer span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.customizer span svg {
  width: 24px;
  padding-right: 5px;
}

.share {
  bottom: 4%;
  position: absolute;
  right: 50%;
}

.zoom {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: border, color 1s ease;
}

.zoom:hover {
  color: black;
}

/* PICKER */

.picker {
  position: absolute !important;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  width: 120px !important;
  height: 120px !important;
}

.color-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.box {
  width: 30px;
  height: 30px;
  border-radius: 10%;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}
.selectedBox {
  width: 30px;
  height: 30px;
  border-radius: 10%;
  outline-style: solid;
  outline-color: red;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}
.box:hover {
  outline-style: solid;
  outline-color: greenyellow;
  cursor: pointer;
}
.decals {
  position: absolute;
  left: 50px;
  bottom: 40px;
}

.decals p {
  font-family: 'Inter var';
  font-size: 0.7rem;
  padding-bottom: 15px;
}

.decals img {
  width: 24px;
  filter: saturate(0) invert(1) brightness(5.5);
  transition: all 0.2s ease-in-out;
}

.decals img:hover {
  filter: none;
  transform: scale(1.2);
  cursor: pointer;
}

.decals--position {
  position: absolute;
  left: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 10px;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: border 1s ease;
}

.decals--position:hover {
  color: black;
  border: 1px solid #00000056;
}

.upload {
  margin-top: -5px;
  cursor: pointer;
}

.upload:hover {
  color: white;
}

.decals--container {
  display: flex;
  gap: 20px;
}

.exit {
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: black;
  box-shadow: inset 0 0 0 0.09px black;
}

.exit:hover {
  box-shadow: inset -250px 0 0 0 black;
}

@media screen and (max-width: 600px) {
  .section--container {
    margin-left: 15vw;
  }

  .section--container h1 {
    font-size: 8rem;
    letter-spacing: -6px;
    line-height: 6rem;
  }

  /*.color-options {
    margin-bottom: 20px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }*/

  .support--content {
    top: -10%;
    left: 0;
  }
  
}
@media screen and (max-width: 600px) {
  .title_message {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}