.advanced-marker-example .photo-gallery {
  width: 100%;
  height: 100%;
  position: relative;
}
.advanced-marker-example .photo-gallery img {
  width: 100%;
  height: 100%;
}

.advanced-marker-example .gallery-navigation {
  position: absolute;
  inset: 0;
  opacity: 0;
  visibility: hidden;
  padding: 16px 8px;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.advanced-marker-example .nav-buttons {
  width: 100%;
  margin: auto 0;

  display: flex;
  justify-content: space-between;

  transform: translateY(50%);
}

.advanced-marker-example .nav-buttons button {
  padding: 8px;
  background: var(--background-color-primary);
  color: var(--estate-green-light);
  border: none;
  box-shadow:
    0px 1.417px 4.25px 1.417px rgba(0, 0, 0, 0.15),
    0px 1.417px 2.833px 0px rgba(0, 0, 0, 0.3);
  opacity: 1;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 10px;

  transition: opacity 0.3s ease-in;
}

.advanced-marker-example .nav-buttons button span {
  font-size: 16px;
}

.advanced-marker-example .nav-buttons button:disabled {
  opacity: 0;
}

.advanced-marker-example .dot {
  cursor: pointer;
  height: 6px;
  width: 6px;
  margin: 0 2px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  display: inline-block;

  transition: background-color 0.3s ease;
}

.advanced-marker-example .dot.active {
  background-color: var(--background-color-primary);
}

.advanced-marker-example .extended .gallery-navigation {
  opacity: 1;
  visibility: visible;

  transition: opacity 0.3s ease-in;
}
